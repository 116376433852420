::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: #e6e6e6;
  border-radius: 20px;
}

.pac-container {
  z-index: 100000;
}

body {
  /* overflow: overlay !important; */
  /* padding-right: 0px !important; */
  -webkit-tap-highlight-color: transparent;
}

:root {
  --toastify-color-progress-success: #409a65 !important;
}

@media (min-width: 601px) {
  .Toastify__toast-container {
    width: fit-content !important;
  }

  .Toastify .Toastify__toast {
    min-height: 48px;
    border-radius: 8px;
  }

  .Toastify__toast-container .Toastify__toast {
    padding: 0px 0px 0px 12px;
    width: fit-content;
  }

  .Toastify__toast .Toastify__toast-body {
    margin: 0px 0px 0px 0px;
    align-items: flex-start;
  }

  .Toastify__toast-body > div:last-child {
    min-height: 48px;
    padding-top: 13px;
  }

  .Toastify__toast .Toastify__toast-body {
    padding: 0px 0px 0px 0px;
    letter-spacing: 0.6px;
  }

  .Toastify__toast-icon.Toastify--animate-icon.Toastify__zoom-enter {
    width: 24px !important;
    height: 24px !important;
    margin-inline-end: 8px !important;
    margin-top: 12px !important;
  }

  .Toastify__toast > svg {
    height: 48px;
    margin-left: 12px;
    margin-right: 16px;
  }

  .Toastify__progress-bar {
    height: 2px !important;
  }
}

@media (max-width: 600px) {
  body {
    overflow: hidden !important;
  }

  .Toastify__toast-theme--colored.Toastify__toast--error {
    color: #302c2c;
    background-color: #efd8db;
    box-shadow: none;
    margin: 16px;
    font-size: 12px;
    border-radius: 8px;
    border: 1px solid #edb3bb;
    height: fit-content;
    gap: 4px;
  }

  .Toastify__toast-theme--colored.Toastify__toast--error svg {
    color: #f95758;
  }

  .Toastify__toast-theme--dark {
    color: #302c2c;
    background-color: #dce6e0;
    box-shadow: none;
    margin: 16px;
    font-size: 12px;
    border-radius: 8px;
    gap: 4px;
    border: 1px solid #bdd7c7;
  }

  .Toastify__toast-icon {
    width: 16px;
    height: 16px;
    margin-inline-end: 8px;
  }

  .Toastify__close-button {
    display: none;
  }

  .Toastify__toast {
    min-height: 40px;
  }

  .Toastify__toast-body {
    margin: 0;
    padding: 0;
  }
}

input:-internal-autofill-selected {
  background-color: transparent !important;
}
